<template>
  <div class="product">
    <div class="topImg hiden">
      <img src="../assets/imgs/product/top_img.png" alt="" />
      <div class="text">
        <h1>核心功能一体化</h1>
        <h2>集日历事件、人脉、纪要和待办为一体的效率工具</h2>
      </div>
    </div>
    <ProductTab />
    <div class="downloadApp hiden">
      <Download />
    </div>
  </div>
</template>
<script>
import ProductTab from "../components/ProductTab.vue";
import Download from "../components/Download.vue";
export default {
  name: "Product",
  components: { ProductTab, Download },
  setup() {
    return;
  },
  methods: {},
  mounted() {},
};
</script>
<style lang="scss" scoped>
.topImg img {
  width: 100%;
}
.product {
  .topImg {
    position: relative;
    .text {
      position: absolute;
      top: 0;
      right: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      h1 {
        font-size: 49px;
        color: #fff;
        line-height: 2.18;
        font-weight: 500;
      }
      h2 {
        font-size: 33px;
        line-height: 1.42;
        color: #fff;
        margin-bottom: 50px;
        font-weight: normal;
      }
    }
  }
}
.downloadApp {
  padding-top: 10px;
  background-color: #f9faff;
}
</style>
